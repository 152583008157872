import React, { FC } from 'react'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { usePopover } from '../../hooks/use-popover'
import { CheckmarkIcon, TranslationLgIcon } from '../../index'
import { ArrowIndicator } from '../arrow-indicator'
import { Container } from '../container'
import { Popover } from '../popover'
import { PopperButton } from '../popper/popper-button'
import { Text } from '../typography'
import { LanguageSelectProps, LanguageSelectVariant } from './types'

const POPOVER_ID = 'language-select'

export const LanguageSelect: FC<LanguageSelectProps> = ({
  selectedOption,
  options,
  hiddenLabel,
  hiddenLabelSelected,
  onClick,
  variant = 'primary',
  popoverId = POPOVER_ID,
  placement = 'bottom-end',
  iconOnly = false,
  ...spacing
}) => {
  const { isOpen } = usePopover(popoverId)

  return (
    <Container {...spacing}>
      <Popover
        placement={placement}
        popoverId={popoverId}
        popperTrigger={
          <DropdownButton variant={variant} aria-label={hiddenLabel}>
            <TranslationLgIcon />
            {!iconOnly && (
              <>
                <Text as="span">{selectedOption.label}</Text>
                <ArrowIndicator ml="0.4rem" open={isOpen} />
              </>
            )}
          </DropdownButton>
        }
      >
        <List>
          {options.map(({ value, label }) => (
            <Item key={value}>
              <PopperButton name={label} onClick={() => onClick(value)} />
              {selectedOption.value === value && (
                <SelectedIndicator>
                  <CheckmarkIcon aria-label={hiddenLabelSelected} />
                </SelectedIndicator>
              )}
            </Item>
          ))}
        </List>
      </Popover>
    </Container>
  )
}

const DropdownButton = styled.span<{ variant: LanguageSelectVariant }>`
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  & > svg:first-of-type {
    fill: ${({ theme }) => theme.colors.icon};
  }
  & > span {
    color: ${({ theme }) => theme.colors.text};
  }
  ${({ variant, theme }) =>
    variant === 'secondary' &&
    `
    border-radius: 0.8rem;
    height: 4.4rem;
    min-width: 4.4rem;
    padding:  1rem 0.8rem;
    border: 1px solid ${theme.colors.grey};
    background-color: transparent;
  `}
`

const Item = styled.li`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
`

const List = styled.ul<SpaceProps>`
  overflow: hidden;
  width: 20rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
`

const SelectedIndicator = styled.span`
  position: absolute;
  right: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
`
