import React, { FC, MouseEvent, useCallback } from 'react'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'
import { Container, Portal } from 'ui'
import { usePopover } from '../../hooks/use-popover'
import { PopoverProps } from './types'

export const Popover: FC<PopoverProps> = ({
  popoverId,
  children,
  popperTrigger,
  placement = 'top-start',
  onClose,
  closeOnChildrenClick = true,
}) => {
  const { isOpen, openPopover, closePopover } = usePopover(popoverId)

  const handleOnClose = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      closePopover()
      onClose?.()
    },
    [closePopover, onClose]
  )

  return (
    <>
      <button
        data-tooltip-id={popoverId}
        data-tooltip-place={placement}
        onClick={openPopover}
        style={{ outline: 'none' }}
      >
        {popperTrigger}
      </button>
      {isOpen && (
        <Portal selectorId="popover">
          <Overlay onClick={handleOnClose} />
          <Tooltip
            opacity="1"
            clickable
            style={{ background: 'none', zIndex: 1000, padding: '0' }}
            id={popoverId}
            role="dialog"
            isOpen={isOpen}
            aria-labelledby={popoverId}
          >
            <Container onClick={closeOnChildrenClick ? handleOnClose : undefined}>
              {children}
            </Container>
          </Tooltip>
        </Portal>
      )}
    </>
  )
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: auto;
  background: transparent;
`
