import React, { ChangeEventHandler, FC, useCallback, useState } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Checkbox } from '../checkbox'
import { Container } from '../container'
import { ChevronDownIcon } from '../icons'
import { Popover } from '../popover'
import { Text } from '../typography'

interface DropdownCheckboxProps {
  name: string
  label: string
}

export interface DropdownSelectProps {
  options: DropdownCheckboxProps[]
  onChange: ChangeEventHandler<HTMLInputElement>
  label: string
  labelColor?: string
  preFix?: JSX.Element
  checkedValues?: string[]
  checkBox?: boolean
  disabled?: boolean
}
export const DropdownCheckbox: FC<DropdownSelectProps> = ({
  options,
  onChange,
  label,
  labelColor,
  preFix,
  checkedValues,
  disabled,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const dropdownRef = React.useRef<HTMLDivElement>(null)

  const handleButtonClick = useCallback(() => {
    setIsDropdownOpen(!isDropdownOpen)
  }, [isDropdownOpen])

  return (
    <DropdownContainer ref={dropdownRef}>
      <Popover
        popoverId="dropdown-checkbox-menu"
        placement="bottom-start"
        closeOnChildrenClick={false}
        popperTrigger={
          <DropdownButton type="button" onClick={handleButtonClick} disabled={disabled}>
            {preFix && preFix}
            <Text color={labelColor}>{label}</Text>
            <ChevronContainer>
              <ChevronDownIcon />
            </ChevronContainer>
          </DropdownButton>
        }
      >
        <DropdownContent>
          {options.map(({ label, name }) => (
            <DropdownCheckBox>
              <Checkbox
                checked={checkedValues?.includes(name)}
                onChange={onChange}
                name={name}
                label={label}
              />
            </DropdownCheckBox>
          ))}
        </DropdownContent>
      </Popover>
    </DropdownContainer>
  )
}

export const DropdownButton = styled('button')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 4.8rem 0.8rem 1.2rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  border-radius: 0.8rem;
  > * + * {
    margin-left: 0.4rem;
  }
`

export const DropdownCheckBox = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => `${theme.colors.black}`};
  align-items: center;
  padding: 0.8rem 1.2rem;
  &:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  :hover {
    background: ${({ theme }) => `${theme.colors.greyLight}`};
  }
  > label {
    background: ${({ theme }) => `${theme.colors.white}`};
  }
  > * + * {
    margin-left: 0.4rem;
  }
`

export const ChevronContainer = styled(Container)`
  position: absolute;
  right: 1.2rem;
`

export const DropdownContainer = styled(Container)`
  position: relative;
  display: flex;
  min-height: 4rem;
  width: fit-content;
  ${space}
`

export const DropdownContent = styled.ul`
  width: min-content;
  min-width: 20rem;
  border-radius: 0.8rem;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyDark};
  overflow: hidden;
`
