import React from 'react'

export const TranslationLgIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74359 3C10.2959 3 10.7436 3.44772 10.7436 4V4.66667H15.4872C16.0395 4.66667 16.4872 5.11438 16.4872 5.66667C16.4872 6.21895 16.0395 6.66667 15.4872 6.66667H14.16C13.4767 8.56508 12.3551 10.3396 11.0967 11.9228L12.9066 13.7039C13.3002 14.0913 13.3053 14.7245 12.9179 15.1181C12.5305 15.5117 11.8973 15.5168 11.5037 15.1294L9.7955 13.4483C8.62282 14.7362 7.39576 15.8813 6.29774 16.8375C5.88124 17.2002 5.24958 17.1565 4.88689 16.74C4.52419 16.3235 4.56781 15.6919 4.98431 15.3292C6.08175 14.3735 7.29033 13.2416 8.42565 11.9815C8.12247 11.6032 7.80585 11.1833 7.51657 10.7813C7.04665 10.1284 6.5902 9.44206 6.38488 9.02502C6.14095 8.52952 6.34487 7.9301 6.84037 7.68616C7.33586 7.44223 7.93529 7.64616 8.17922 8.14165C8.30211 8.39127 8.66617 8.9549 9.13985 9.61303C9.33522 9.88449 9.53906 10.158 9.73766 10.4147C10.66 9.22121 11.4546 7.96122 12.0107 6.66667H4C3.44772 6.66667 3 6.21895 3 5.66667C3 5.11438 3.44772 4.66667 4 4.66667H8.74359V4C8.74359 3.44772 9.19131 3 9.74359 3ZM16.7179 9.25C17.1348 9.25 17.5079 9.50854 17.6543 9.89882L20.9363 18.6488C21.1303 19.1659 20.8683 19.7423 20.3512 19.9363C19.8341 20.1303 19.2577 19.8683 19.0637 19.3512L18.3692 17.5H15.0667L14.3722 19.3512C14.1782 19.8683 13.6018 20.1303 13.0847 19.9363C12.5676 19.7423 12.3056 19.1659 12.4996 18.6488L15.7816 9.89882C15.928 9.50854 16.3011 9.25 16.7179 9.25ZM15.8168 15.5H17.6191L16.7179 13.0975L15.8168 15.5Z"
      />
    </svg>
  )
}
