import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Container, generalSettings } from '..'
import { GeneralScrollContainerProps, ScrollContainerProps } from './types'

//TODO: add prop for fading effect
export const ScrollContainer = forwardRef<HTMLDivElement, GeneralScrollContainerProps>(
  ({ children, ...otherStyles }, ref) => {
    // tabIndex is necessary for accessibility https://dequeuniversity.com/rules/axe/4.4/scrollable-region-focusable?application=axeAPI
    return (
      <StyledScrollContainer ref={ref} tabIndex={0} {...otherStyles}>
        <>{children}</>
      </StyledScrollContainer>
    )
  }
)

ScrollContainer.displayName = 'ScrollContainer'

const StyledScrollContainer = styled(Container).withConfig(
  generalSettings
)<GeneralScrollContainerProps>`
  overflow-y: auto;
  height: ${(props) => (props.height ? props.height : '100%')};

  ${(props) =>
    props.hideScrollbar &&
    css`
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    `}

  ${(props) =>
    !props.hideScrollbar &&
    css`
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 100vw;
        border: 3px solid transparent;
        background-clip: padding-box;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.grey};
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 100vw;
        margin-block: 0.5rem;
      }

      @-moz-document url-prefix() {
        scrollbar-width: thin;
        scrollbar-color: ${(props) => props.theme.colors.grey} transparent;
      }
    `}
`
