import React, { FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Text, CheckmarkIcon, CloseIcon, DashIcon } from '../../index'

const CheckboxContainer = styled.label<SpaceProps>`
  height: 2rem;
  display: flex;
  align-items: center;
  ${space}

  &:hover {
    cursor: pointer;
  }
`

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.greyDark}`};
  transition: ${({ theme }) => `all ${theme.transitionTimes.short}`};

  ${HiddenCheckbox}:focus + & {
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }

  ${HiddenCheckbox}:disabled + & {
    opacity: 0.2;
  }

  & svg {
    transition: opacity ${({ theme }) => theme.transitionTimes.short};
    fill: ${({ theme }) => theme.colors.primary};
    opacity: 0;
  }

  ${HiddenCheckbox}:checked + & {
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
    & svg {
      opacity: 1;
    }
  }
`

export enum CheckboxVariant {
  DEFAULT = 'default',
  LINE = 'line',
}

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  spaceProps?: SpaceProps
  variant?: CheckboxVariant
}

export const Checkbox: FC<CheckboxProps> = ({
  label,
  name,
  spaceProps,
  variant = CheckboxVariant.DEFAULT,
  ...props
}) => (
  <CheckboxContainer htmlFor={name} {...spaceProps}>
    <HiddenCheckbox type="checkbox" id={name} {...props} />
    <StyledCheckbox>
      {variant === CheckboxVariant.DEFAULT ? (
        <CheckmarkIcon height={18} width={18} />
      ) : (
        <DashIcon />
      )}
    </StyledCheckbox>
    {label && <Text ml="1.2rem">{label}</Text>}
  </CheckboxContainer>
)
