import React from 'react'

export const TranslationSmIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.32908 0.399994C4.66045 0.399994 4.92908 0.668623 4.92908 0.999994V1.51111H8.15814C8.48951 1.51111 8.75814 1.77973 8.75814 2.11111C8.75814 2.44248 8.48951 2.71111 8.15814 2.71111H7.22639C6.76903 4.00303 6.00266 5.21123 5.1416 6.28725L6.39096 7.5168C6.62714 7.74923 6.63018 8.12912 6.39774 8.3653C6.16531 8.60148 5.78542 8.60451 5.54924 8.37208L4.36097 7.20265C3.56749 8.07964 2.73346 8.85894 1.98806 9.50803C1.73816 9.72565 1.35916 9.69948 1.14155 9.44958C0.923933 9.19968 0.950105 8.82068 1.20001 8.60307C1.94508 7.95425 2.76766 7.18307 3.53793 6.32337C3.32622 6.06126 3.10233 5.76515 2.8985 5.48195C2.5851 5.0465 2.28388 4.59301 2.14975 4.32056C2.00339 4.02326 2.12574 3.66361 2.42304 3.51725C2.72034 3.37089 3.07999 3.49324 3.22635 3.79054C3.31103 3.96253 3.55681 4.34238 3.87247 4.78096C4.02034 4.98641 4.17566 5.19387 4.32514 5.38511C4.98688 4.53809 5.55576 3.63817 5.94103 2.71111H0.75918C0.427809 2.71111 0.15918 2.44248 0.15918 2.11111C0.15918 1.77973 0.427809 1.51111 0.75918 1.51111H3.72908V0.999994C3.72908 0.668623 3.99771 0.399994 4.32908 0.399994ZM8.97865 4.56666C9.22874 4.56666 9.45261 4.72179 9.54043 4.95595L11.1032 9.12259L11.7285 10.7893C11.8448 11.0995 11.6877 11.4454 11.3774 11.5618C11.0672 11.6782 10.7213 11.521 10.6049 11.2107L10.1257 9.93333H7.83159L7.35239 11.2107C7.236 11.521 6.89013 11.6782 6.57987 11.5618C6.26961 11.4454 6.11245 11.0995 6.22884 10.7893L6.85406 9.12262L8.41686 4.95595C8.50469 4.72179 8.72856 4.56666 8.97865 4.56666ZM8.2817 8.73333H9.67559L8.97865 6.87517L8.2817 8.73333Z"
      />
    </svg>
  )
}
